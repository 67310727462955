import { Fragment, useState, useEffect } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, UserCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const ourschool = [
  { name: "About School", href: "/about_School" },
  { name: "School Affiliation", href: "/school_affiliation" },
  { name: "Details of Faculty", href: "faculty" },
  { name: "Salient Features", href: "/salient_features" },
  { name: "Lab & Facilities", href: "/lab_fac" },
  { name: "Student Strength", href: "/student_strength" },
  { name: "Achievements", href: "/achievements" },
  { name: "Activities", href: "/activities" },
  { name: "Certificates", href: "/our_certificates" },
  { name: "Mandatory Public Disclosure", href: "/disclosure" },
  { name: "Our Video", href: "/our_video" },
];
const management = [
  { name: "About Society", href: "/society" },
  { name: "Managing Committee & PTA", href: "/committee" },
  { name: "Messages", href: "/messages" },
];
const admission = [
  { name: "Fee Structure", href: "/fee_structure" },
  { name: "Admission Form", href: "/admission_form" },
  { name: "Transfer Certificate Issued", href: "/transfer_certificate" },
];
const academics = [
  { name: "List of Books", href: "/books" },
  { name: "Syllabus", href: "/syllabus" },
  // { name: 'Time Table', href: '/time_table', },
  { name: "Results", href: "/result" },
];
const admin = [
  { name: "Software Login", href: "https://tech.gnkschool.info" },
  { name: "Website Login", href: "/signin" },
];

const recruitment = [
  { name: "Advertisement / Notice", href: "/notice" },
  { name: "Form", href: "/form" },
  { name: "recruitment", href: "/recruitmentResult" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Mnav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      // setIsNavbarVisible(scrollTop > 10);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="fixed z-50 w-full">
        <header className="shadow px-0 w-full  bg-orange   py-2 md:py-4 lg:py-4">
          <div className="w-full  container mx-auto px-4 md:px-6 lg:px-8 flex items-center  ">
            <Link to="/" className="mr-2 ">
              <img
                className="h-14 md:h-12 lg:h-16 "
                src="./images/school_logo.png"
                alt="Logo"
              />
            </Link>
            <div className="flex justify-between items-center w-full">
              <div className="w-full  md:w-[60%] flex items-center mr-1 ">
                <h1 className=" pt-3 mb-2 text-[12px] xs:text-[14px] sm:text-[15px] md:text-xl lg:text-3xl font-bold tracking-tight text-white">
                  GURU NANAK KHALSA SR. SEC. SCHOOL
                </h1>
              </div>
              <div className="flex lg:hidden justify-end">
                <button
                  type="button"
                  className="  inline-flex items-center justify-between rounded-md text-gray-100"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="  w-full md:w-[40%]  hidden lg:flex justify-end">
                <Link
                  to="/donate"
                  className="transition font-semibold duration-400 text-center mb-2 sm:mb-0 px-10 py-2 w-full sm:w-[50%] bg-white text-orange rounded-md "
                >
                  Donate
                </Link>
              </div>
            </div>
          </div>
        </header>
        <header
          className={`bg-white  shadow w-full transition-all duration-300 `}
        >
          <nav
            className="mx-auto hidden lg:flex max-w-[100%]  items-center justify-between p-2 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link to="#" className="-m-1.5 py-1">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-10 w-11 hidden"
                  src="./images/school_logo.png"
                  alt=""
                />
              </Link>
            </div>
            {mobileMenuOpen && (
              <button onClick={() => setIsOpen(false)}>
                <XMarkIcon
                  className="h-6 w-6 cursor-pointer"
                  aria-hidden="true"
                />
              </button>
            )}

            <Popover.Group className="hidden  w-full  flex items-center justify-center  z-20 lg:flex lg:gap-x-4 2xl:gap-x-8">
              <Link
                to="/"
                className="text-md   hover:text-orange font-semibold leading-6 text-orange transition duration-400"
              >
                Home
              </Link>
              <Popover className="relative ">
                <Popover.Button
                  onMouseEnter={() => setIsOpen(true)}
                  onMouseLeave={() => setIsOpen(false)}
                  className="flex outline-none   hover:text-orange items-center gap-x-1 text-md font-semibold leading-6 text-blue transition duration-400"
                >
                  Our School
                  <ChevronDownIcon
                    className="h-5 w-5 flex-none text-orange"
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  onMouseEnter={() => setIsOpen(true)}
                  onMouseLeave={() => setIsOpen(false)}
                  show={isOpen}
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-8  top-full z-10 mt-3 bg-orange  w-max  max-w-md overflow-hidden rounded-lg shadow-lg ring-1 ring-blue/5">
                    <div className="p-4">
                      {ourschool.map((item) => (
                        <div
                          key={item.name}
                          className="group relative flex items-center gap-x-6 rounded-lg p-2 text-md leading-6 hover:bg-gray-100 transition duration-500"
                        >
                          <div className="flex-auto">
                            <Link
                              to={item.href}
                              className="block font-semibold text-white hover:text-orange transition duration-500"
                            >
                              {item.name}
                              <span className="absolute inset-0" />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>

              <Popover className="relative">
                <Popover.Button
                  onMouseEnter={() => setIsOpen2(true)}
                  onMouseLeave={() => setIsOpen2(false)}
                  className="flex outline-none hover:text-orange items-center gap-x-1 text-md font-semibold leading-6 text-blue transition duration-400"
                >
                  Management
                  <ChevronDownIcon
                    className="h-5 w-5 flex-none text-orange"
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  onMouseEnter={() => setIsOpen2(true)}
                  onMouseLeave={() => setIsOpen2(false)}
                  show={isOpen2}
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-max max-w-md overflow-hidden rounded-lg bg-orange shadow-lg ring-1 ring-blue/5">
                    <div className="p-4">
                      {management.map((item) => (
                        <div
                          key={item.name}
                          className="group relative flex items-center gap-x-6 rounded-lg p-2 text-md leading-6 hover:bg-gray-100 transition duration-500"
                        >
                          <div className="flex-auto">
                            <Link
                              to={item.href}
                              className="block font-semibold text-white hover:text-orange transition duration-500"
                            >
                              {item.name}
                              <span className="absolute inset-0" />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>

              <Link
                to="/principal_desk"
                className="text-md hover:text-orange font-semibold leading-6 text-blue transition duration-400"
              >
                Principal's Desk
              </Link>

              <Popover className="relative">
                <Popover.Button
                  onMouseEnter={() => setIsOpen3(true)}
                  onMouseLeave={() => setIsOpen3(false)}
                  className="flex outline-none  hover:text-orange items-center gap-x-1 text-md font-semibold leading-6 text-blue transition duration-400"
                >
                  Admission
                  <ChevronDownIcon
                    className="h-5 w-5 flex-none text-orange"
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  show={isOpen3}
                  onMouseEnter={() => setIsOpen3(true)}
                  onMouseLeave={() => setIsOpen3(false)}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-8 bg-orange top-full z-10 mt-3 w-max max-w-md overflow-hidden rounded-lg  shadow-lg ring-1 ring-blue/5">
                    <div className="p-4">
                      {admission.map((item) => (
                        <div
                          key={item.name}
                          className="group relative flex items-center gap-x-6 rounded-lg p-2 text-md leading-6 hover:bg-gray-100 transition duration-500"
                        >
                          <div className="flex-auto">
                            <Link
                              to={item.href}
                              className="block font-semibold text-white hover:text-orange transition duration-500"
                            >
                              {item.name}
                              <span className="absolute inset-0" />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>

              <Popover className="relative">
                <Popover.Button
                  onMouseEnter={() => setIsOpen4(true)}
                  onMouseLeave={() => setIsOpen4(false)}
                  className="flex outline-none hover:text-orange items-center gap-x-1 text-md font-semibold leading-6 text-blue transition duration-400"
                >
                  Academics
                  <ChevronDownIcon
                    className="h-5 w-5 flex-none text-orange"
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  show={isOpen4}
                  onMouseEnter={() => setIsOpen4(true)}
                  onMouseLeave={() => setIsOpen4(false)}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-max max-w-md overflow-hidden rounded-lg bg-orange shadow-lg ring-1 ring-blue/5">
                    <div className="p-4">
                      {academics.map((item) => (
                        <div
                          key={item.name}
                          className="group relative flex items-center gap-x-6 rounded-lg p-4 text-md leading-6 hover:bg-gray-100 transition duration-500"
                        >
                          <div className="flex-auto">
                            <Link
                              to={item.href}
                              className="block font-semibold text-white hover:text-orange transition duration-500"
                            >
                              {item.name}
                              <span className="absolute inset-0" />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>

              <Link
                to="/gallery"
                className="text-md hover:text-orange font-semibold leading-6 text-blue transition duration-400"
              >
                Gallery
              </Link>

              <Link
                to="/donate"
                className="transition lg:hidden font-semibold duration-400 text-center mb-2 sm:mb-0 px-10 py-2 w-full sm:w-[50%] bg-white text-orange rounded-md "
              >
                Donate
              </Link>

              <Popover className="relative">
                <Popover.Button
                  onMouseEnter={() => setIsOpen5(true)}
                  onMouseLeave={() => setIsOpen5(false)}
                  className="flex outline-none hover:text-orange items-center gap-x-1 text-md font-semibold leading-6 text-blue transition duration-400"
                >
                  Recruitment
                  <ChevronDownIcon
                    className="h-5 w-5 flex-none text-orange"
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  onMouseEnter={() => setIsOpen5(true)}
                  onMouseLeave={() => setIsOpen5(false)}
                  as={Fragment}
                  show={isOpen5}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-max max-w-md overflow-hidden rounded-lg bg-orange shadow-lg ring-1 ring-blue/5">
                    <div className="p-4">
                      {recruitment.map((item) => (
                        <div
                          key={item.name}
                          className="group relative flex items-center gap-x-6 rounded-lg p-2 text-md leading-6 hover:bg-gray-100 transition duration-500"
                        >
                          <div className="flex-auto">
                            <Link
                              to={item.href}
                              className="block font-semibold text-white hover:text-orange transition duration-500"
                            >
                              {item.name}
                              <span className="absolute inset-0" />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>

              <Link
                to="/news_updates"
                className="text-md hover:text-orange font-semibold leading-6 text-blue transition duration-400"
              >
                News Updates
              </Link>
              <Link
                to="/contact"
                className="text-md  text-blue   hover:text-orange font-semibold leading-6 transition duration-400 "
              >
                Contact Us
              </Link>
              <Popover className="relative">
                <Popover.Button
                  onMouseEnter={() => setIsOpen6(true)}
                  onMouseLeave={() => setIsOpen6(false)}
                  className="flex outline-none hover:text-orange items-center gap-x-1 text-md font-semibold leading-6 text-blue transition duration-400"
                >
                  <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>

                <Transition
                  onMouseEnter={() => setIsOpen6(true)}
                  onMouseLeave={() => setIsOpen6(false)}
                  as={Fragment}
                  show={isOpen6}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-max max-w-md overflow-hidden rounded-lg bg-orange shadow-lg ring-1 ring-blue/5">
                    <div className="p-4">
                      {admin.map((item) => (
                        <div
                          key={item.name}
                          className="group relative flex items-center gap-x-6 rounded-lg p-2 text-md leading-6 hover:bg-gray-100 transition duration-500"
                        >
                          <div className="flex-auto">
                            <Link
                              to={item.href}
                              className="block font-semibold text-white hover:text-orange transition duration-500"
                            >
                              {item.name}
                              <span className="absolute inset-0" />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </Popover.Group>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={() => setMobileMenuOpen(false)}
          >
            <div className="fixed inset-0 z-10" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-blue/10">
              <div className="flex items-center justify-between">
                <Link to="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-10 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    <Link
                      onClick={() => setMobileMenuOpen(false)}
                      to="/"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-blue hover:bg-gray-100 transition duration-400"
                    >
                      Home
                    </Link>
                    <Disclosure as="div" className="-mx-3">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-blue hover:bg-gray-100">
                            Our School
                            <ChevronDownIcon
                              className={classNames(
                                open ? "rotate-180" : "",
                                "h-5 w-5 text-orange flex-none"
                              )}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="mt-2 space-y-2">
                            {[...ourschool].map((item) => (
                              <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className="block rounded-lg py-2 pl-6 pr-3 text-md font-semibold leading-7 text-blue hover:bg-gray-100"
                              >
                                {item.name}
                              </Disclosure.Button>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>

                    <Disclosure as="div" className="-mx-3">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-blue hover:bg-gray-100">
                            Management
                            <ChevronDownIcon
                              className={classNames(
                                open ? "rotate-180" : "",
                                "h-5 w-5 text-orange flex-none"
                              )}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="mt-2 space-y-2">
                            {[...management].map((item) => (
                              <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className="block rounded-lg py-2 pl-6 pr-3 text-md font-semibold leading-7 text-blue hover:bg-gray-100"
                              >
                                {item.name}
                              </Disclosure.Button>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>

                    <Link
                      to="/principal_desk"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-blue hover:bg-gray-100"
                    >
                      Principal's Desk
                    </Link>

                    <Disclosure as="div" className="-mx-3">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-blue hover:bg-gray-100">
                            Admission
                            <ChevronDownIcon
                              className={classNames(
                                open ? "rotate-180" : "",
                                "h-5 w-5 text-orange flex-none"
                              )}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="mt-2 space-y-2">
                            {[...admission].map((item) => (
                              <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className="block rounded-lg py-2 pl-6 pr-3 text-md font-semibold leading-7 text-blue hover:bg-gray-100"
                              >
                                {item.name}
                              </Disclosure.Button>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>

                    <Disclosure as="div" className="-mx-3">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-blue hover:bg-gray-100">
                            Academics
                            <ChevronDownIcon
                              className={classNames(
                                open ? "rotate-180" : "",
                                "h-5 w-5 text-orange flex-none"
                              )}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="mt-2 space-y-2">
                            {[...academics].map((item) => (
                              <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className="block rounded-lg py-2 pl-6 pr-3 text-md font-semibold leading-7 text-blue hover:bg-gray-100"
                              >
                                {item.name}
                              </Disclosure.Button>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>

                    <Link
                      to="/gallery"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-blue hover:bg-gray-100"
                    >
                      Gallery
                    </Link>

                    <Disclosure as="div" className="-mx-3">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-blue hover:bg-gray-100">
                            Recruitment
                            <ChevronDownIcon
                              className={classNames(
                                open ? "rotate-180" : "",
                                "h-5 w-5 text-orange flex-none"
                              )}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="mt-2 space-y-2">
                            {[...recruitment].map((item) => (
                              <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className="block rounded-lg py-2 pl-6 pr-3 text-md font-semibold leading-7 text-blue hover:bg-gray-100"
                              >
                                {item.name}
                              </Disclosure.Button>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>

                    <Link
                      to="/news_updates"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-blue hover:bg-gray-100"
                    >
                      News Updates
                    </Link>
                    <Link
                      to="/contact"
                      className="-mx-3 block  rounded-lg px-3 py-2 text-base font-semibold leading-7 text-blue hover:bg-gray-100"
                    >
                      Contact Us
                    </Link>

                    <Disclosure as="div" className="-mx-3">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex bg-orange text-white w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 transition duration-400 text-blue hover:bg-blue">
                            Admin Login
                            <ChevronDownIcon
                              className={classNames(
                                open ? "rotate-180" : "",
                                "h-5 w-5 text-white flex-none"
                              )}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="mt-2 space-y-2">
                            {[...admin].map((item) => (
                              <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className="block rounded-lg py-2 pl-6 pr-3 text-md font-semibold leading-7 text-blue hover:bg-gray-100"
                              >
                                {item.name}
                              </Disclosure.Button>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
      <div className="h-20 z-10"> </div>
    </>
  );
}
